const AWS = require('aws-sdk');

const usewriteToDynamoDB = async (key, date, expense, description) => {
  
  const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_KEY;
  const region = process.env.REACT_APP_REGION;
 
  AWS.config.update({ 
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: region, 
  })
  
  const dynamoDB = new AWS.DynamoDB.DocumentClient();


  try {
    const params = {
      TableName: 'Mengco-DB-Table',
      Item: {
        'key': key,
        'Date': date,
        'Expense': expense,
        'Description': description,
      }
    };

    await dynamoDB.put(params).promise();
    console.log('Item added to DynamoDB table successfully');
  } catch (error) {
    console.error('Error writing to DynamoDB:', error);
    
  }
};

export  {usewriteToDynamoDB};

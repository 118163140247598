import { DynamoDB } from 'aws-sdk';


const useDataFromDynamoDB = async () => {
  var AWS = require('aws-sdk');
  const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_KEY;
  const region = process.env.REACT_APP_REGION
  
  AWS.config.update({ 
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: region, 
  })

const dynamoDB = new DynamoDB.DocumentClient();


  try {
    const params = {
      TableName: 'Mengco-DB-Table',
    };

    const result = await dynamoDB.scan(params).promise();
    console.log('Items:', result.Items);
    return result.Items; // Return the items from the function
  } catch (error) {
    console.error('Error fetching items from DynamoDB:', error);
    throw error; // Re-throw the error for handling in the calling code
  }
};

export {useDataFromDynamoDB};
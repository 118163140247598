const AWS = require('aws-sdk');

const usehandleFileUpload = async (file, key) => {
    try {
      const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
      const secretAccessKey = process.env.REACT_APP_KEY;
      const region = process.env.REACT_APP_REGION;
      console.log(accessKeyId)
      console.log(secretAccessKey)
      console.log(region)
      AWS.config.update({ 
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        region: region, 
      })
      console.log(accessKeyId)
      console.log(region)  
      const s3 = new AWS.S3();
  
      // Specify S3 bucket and key (path) for the uploaded file
      const bucketName = process.env.REACT_APP_S3_BUCKET_NAME;
      console.log(bucketName)
      // Ensure that a file is selected before attempting to upload
      if (!file) {
        console.error('No file selected for upload.');
        return;
      }
  
      const key = `uploads/${file.name}`;
  
      // Set up parameters for S3 upload
      const params = {
        Bucket: bucketName,
        Key: key, //should be the name of file
        Body: file,
      };
  
      // Upload the file to S3
      const response = await s3.upload(params).promise();
  
      console.log('File uploaded successfully:', response.Location);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  
  export {usehandleFileUpload };
import React from "react";
// import { Link } from 'react-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';

function Navigation(props) {

  
  return (
    <div className="Navigation custom-font-nav ">
        <nav className="navbar navbar-expand-lg ">
          <a className="navbar-brand px-3" href="/">Mengco Media</a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" id="dropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Social Media
                </a>
                <div className="dropdown-menu" aria-labelledby="dropdown1">
                  <a className="dropdown-item" href="https://youtube.com/@mengcomedia3594">Mengco Media Youtube</a>
                  <a className="dropdown-item" href="https://www.instagram.com/mengcomedia">Mengco Media Instagram</a>
                  <a className="dropdown-item" href="https://tiktok.com/@mengcomedia">Mengco Media Tiktok</a>
                  <a className="dropdown-item" href="https://www.instagram.com/bubsnbubsy">Bubsnbubsy Instagram</a>
                  <a className="dropdown-item" href="https://tiktok.com/@bubsnbubsy">Bubsnbubsy Tiktok</a>
                  
                </div>
              </li>
              {/* <li className="nav-item active">
                <Link className="nav-link"
                    activeClass="active"
                    to="team"
                    spy={true}
                    smooth={true}
                    offset={-70} // Adjust the offset as needed
                    duration={200}
                  >
                    Start
                </Link>
              </li> */}
                

             <li className="nav-item active">
              <a className="nav-link" href="/fileuploadpage">Upload</a>
             </li>
             <li className="nav-item active">
              <a className="nav-link" href="/About">About</a>
             </li>
             <li className="nav-item active">
              <a className="nav-link" href="/Videos">Videos</a>
             </li>
            </ul>
          </div>
        </nav>

    </div>
  );

  // };
}

export default Navigation;
// GoogleAnalytics.js

import { useEffect } from 'react';

const GoogleAnalytics = () => {
    useEffect(() => {
        // Define dataLayer globally
        window.dataLayer = window.dataLayer || [];
    
        // Add Google Analytics script dynamically
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=G-1JPW9JX13F`;
        document.head.appendChild(script);
    
        // Initialize Google Analytics
        window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    
        // Cleanup function to remove the script when component unmounts
        return () => {
          document.head.removeChild(script);
        };
      }, []);
    
      return null;
    };
    
    export default GoogleAnalytics;
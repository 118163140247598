import React, { useState, useEffect } from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProfilePicture from '../images/20231101_194421.jpg'
import rome from '../images/SAM_4889.JPG'

function About(props) {
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
      // Trigger the animation after a delay (adjust as needed)
      const animationTimeout = setTimeout(() => {
        setIsAnimated(true);
      }, 1000);
  
      // Cleanup timeout on component unmount
      return () => clearTimeout(animationTimeout);
    }, []);



    return (
        <div>
          <div className="container my-1 py-2 text-center">
            <div className="row align-items-center">
            
              <div className="col-md-6 order-md-1">
                <img
                  src={ProfilePicture}
                  alt="Moving"
                  className="img-fluid rounded w-100 mb-3 py-4 moveAnimation"
                />
              </div>
              <div className="col-md-6 order-md-2">
                <h1 name="targetComponent" className={`header slideInText ${isAnimated ? 'visible' : ''}`}>
                  Hi! I'm Carlo
                </h1>
                
                  <h4 className={`slideInText px-6 ${isAnimated ? 'visible' : ''}`}>
                    Engineer, Aspiring Content Creator, Amateur Chef, Travel and Financial Enthusiast. My mission is to inspire others by documenting and sharing my life journey.
                  </h4>
                
              </div>
            </div>
          </div>

        <div className='divColor py-4'>
            <p className='w-50 mx-auto fs-5'>After graduating from college in May 2020, I found myself burdened with over $131,000 in student loan debt. 
            Despite starting with a high salary, I realized that it would take multiple years to pay off my student loans, leading to a significant shift in my outlook on life. 
            From that day forward, I understood that I would need to make numerous sacrifices to alleviate this heavy financial burden.
            </p>
            <p className='w-50 mx-auto fs-5'>
            Reflecting on my debt journey now, I realize it was more challenging than I initially imagined. I remember enduring a job I despised, working long hours, and sacrificing holidays. 
            The highly stressful work environment, coupled with a difficult boss, left me feeling hopeless. 
            Additionally, my debt made it challenging to spend time with my girlfriend, coworkers, and friends, as I hesitated to spend money.
           By August 2023, the massive debt had caused significant anxiety, and I was thoroughly burnt out.</p> 
            <p className='w-50 mx-auto fs-5'>
            Fortunately, life took a positive turn in October 2023 when I secured a new position in the tech industry, allowing for a proper work-life balance.
            I saw a light at the end of the tunnel. Adopting a slower approach to paying off my loans, I began to enjoy life once again.
            As of February 23, 2024, I am delighted to announce that I have completely paid off all my loans. It took only 3 years and 9 months, but I am glad I persevered, as now I can live a life like no else.
            </p>
          </div>

   
      <img className ='img-fluid my-5' src={rome}></img>   
      </div>
      );
};

export default About;


import React from 'react';
import Navigation from './component/navbar';
import Header from './component/header';
import FileUploadPage from './component/FileUploadPage';
import About from './component/About';
import Videos from './component/Videos';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './component/Homepage';
import GoogleAnalytics from './component/GoogleAnalytics';
import { Helmet } from 'react-helmet';


function App() {

  return (
    <Router>
      <div className="App">
        <GoogleAnalytics />
        <Helmet>
          <title>Mengco Media</title>
        </Helmet>
        <Navigation />
        
        <Routes>
          <Route path="/" element={
            <>
            <Header />
            <Homepage />
            </>
          }>
          </Route>
          <Route path="/about"  element={<About />}></Route>
          <Route path="/fileuploadpage"  element={<FileUploadPage />}></Route>
          <Route path="/Videos" element={<Videos />}></Route>
       </Routes>
       
      </div>
    </Router>

  
  );
}
export default App;

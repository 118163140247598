import React from "react";
import '../App.css';

function Header(props) {

    return (
       <div className='container mt-5   text-left'>
            <h3>Monthly Expense Blog</h3>
        </div>

  );
}

export default Header;
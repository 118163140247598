import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const LimitedParagraph = ({ text, maxLength }) => {
  const [showMore, setShowMore] = useState(false);
  const truncatedText = showMore ? text : text.slice(0, 200);

  return (
    <div>
      <p>{truncatedText}</p>
      {text.length > maxLength && (
        <button class="btn btn-outline-secondary" onClick={() => setShowMore(!showMore)}>
          {showMore ? 'Read Less' : 'Read More'}
        </button>
      )}
    </div>
  );
};

export default function Post(props) {
    return (
        <div class="col-lg-3 col-md-6 pt-5 ">
        <div>
          <div >
          <img src={props.image} alt="" class="img-fluid rounded w-80 mb-3"/>
          <h6 id = "Date">{props.date}</h6>
          <h3 class= 'text-center'>{props.expense}</h3>
          <LimitedParagraph text={props.description} maxLength={100} />
          </div>
          
        </div>
      </div>
    )
}
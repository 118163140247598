const Videos = () => {
    return (
        <div>
            <div class="my-1 text-left">
                <li>
                <a href="https://youtu.be/xfMSnmVa4Ro">Teopengco Morong beach 96</a>
                </li>
                <li>
                <a href="https://youtu.be/cQdwhFmw45E">Dizon 2002 NY and DC</a>
                </li>
                <li>
                <a href="https://youtu.be/B4dsZcz9k1U">Jojo Maryland 03</a>
                </li>
                <li>
                <a href="https://youtu.be/P6mysPTXPys">Jojo Maryland 03</a>
                </li>
                <li>
                <a href="https://youtu.be/o08VtusZCtA">Wowowee</a>
                </li>
                <li>
                <a href="https://youtu.be/dqJ1niFLbho">Teopengco New Year Dec 31, 1995</a>
                </li>
                <li>
                <a href="https://youtu.be/3ri8Dj4wKQw">Teopengco Mt Samat</a>
                </li>
                <li>
                <a href="https://youtu.be/DxLMloQ6Nsw">Teopengco Morong 1996</a>
                </li>
                <li>
                <a href="https://youtu.be/EtKmQk4Rq6A">Mila Ricky Wedding 1</a>
                </li>
                <li>
                <a href="https://youtu.be/HQWXBFL6SK8">Mila Ricky Wedding 2</a>
                </li>
                <li>
                <a href="https://youtu.be/QXYj4LIk2b4">Teopengco 04 / Mikee Montessori</a>
                </li>
                <li>
                <a href="https://youtu.be/INMW6S6c2o8">Mikee Christening 1</a>
                </li>
                <li>
                <a href="https://youtu.be/GR4RuMBXWVg">Mikee Christening 2</a>
                </li>
                <li>
                <a href="https://youtu.be/jo-sepKNEs4">Mikee Bday 2002</a>
                </li>
                <li>
                <a href="https://youtu.be/nT2Za1Ii4dc">Mikee 10th Month</a>
                </li>
                <li>
                <a href="https://youtu.be/gRLRfB1uEXk">Mikee 5 Month</a>
                </li>
                <li>
                <a href="https://youtu.be/WA7JUrQ86EY">Mikee 2 Month</a>
                </li>
                <li>
                <a href="https://youtu.be/4-6IaFn-JHE">Mikee 1st Bday</a>
                </li>
                <li>
                <a href="https://youtu.be/fKocz87pGo4">Teopengco Jan 1999</a>
                </li>
                <li>
                <a href="https://youtu.be/wM2EkM2NwO0">Villanueva Florida Vacation 99</a>
                </li>
                <li>
                <a href="https://youtu.be/ktYCn0Yp2Dg">Teopengco Baguio 1998</a>
                </li>
                <li>
                <a href="https://youtu.be/D4dq3_Jg0B0">Teopengco Aug 22 and 29 2004 Swimming Villa</a>
                </li>
                <li>
                <a href="https://youtu.be/onJV58HCI3k">Ate Emma Ate Merlyn Restaurant Dining</a>
                </li>
                <li>
                <a href="https://youtu.be/RKEoO-TFVqU">Michael Leuse Wedding</a>
                </li>
                <li>
                <a href="https://youtu.be/f0FMqEK1GOM">Joel Marilou Wedding</a>
                </li>
                <li>
                <a href="https://youtu.be/SzQyz3NAafo">Teopengco Christmas 2001</a>
                </li>
                <li>
                <a href="https://youtu.be/DFQRm0DQ9cI">Baby Mikee with Grand Parents</a>
                </li>
                <li>
                <a href="https://youtu.be/3QNG7_3aBuI">Teopengco May 2000</a>
                </li>
                <li>
                <a href="https://drive.google.com/file/d/1GO6MaC1XrGM7hO-CTfW6bakDx-e-Rmli/view?usp=drive_link">Teopengco April 2000</a>
                </li>
                <li>
                <a href="https://youtu.be/yMBv6yeg6Zk">Teopengco May 2001</a>
                </li>
                <li>
                <a href="https://youtu.be/Cx5LX9-ldBU">Teopengco March 2001</a>
                </li>
                <li>
                <a href="https://youtu.be/U_Vvwh3wO24">Marylene's Wedding Part 2</a>
                </li>
                <li>
                <a href="https://youtu.be/O2mQOSwX5ns">Marylene's Wedding Part 1</a>
                </li>
                
                <li>
                <a href="https://youtu.be/HZSYVidoURE">Abucay Subic 2003 part 1</a>
                </li>
                <li>
                <a href="https://youtu.be/yvirELVZtos">Abucay Subic 2003 part 2</a>
                </li>
                <li>
                <a href="https://drive.google.com/file/d/1cLfRhk2AP1OjntmCUqzYzOOnirdwNx8a/view?usp=drive_link">Teopengco Pool 1999</a>
                </li>

            </div>
        </div>
    );
};

export default Videos;
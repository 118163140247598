import React, { useEffect, useState } from 'react';
import {useDataFromDynamoDB} from './utils/DataFromDynamoDB';
import {usefetchImagesFromS3} from './utils/S3read';
import Post from './Post';
import '../App.css';

const Homepage = () => {
    const [imageKeys, setImageKeys] = useState([]);
    const [date, setDate] = useState([]);
    const [expense, setExpense] = useState([]);
    const [description, setDescription] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);

    useEffect(() => {
        const DynamoDBData = async () => {
            try {
                const data = await useDataFromDynamoDB();
                //sort the data by date
                const items = data.sort((a, b) => new Date(b.Date) - new Date(a.Date));
                console.log('Items:', items);
                // Extract keys from items and set to imageKeys variable
                const imageKeys = items.map(item => item.key);
                console.log('Image Keys:', imageKeys);
                setImageKeys(imageKeys);// imageKeys = imageKeys from items.map

                const date_item = items.map(item => item.Date);
                console.log('Dates:', date_item);
                setDate(date_item);

                const expense_item = items.map(item => item.Expense);
                console.log('expenses:', expense_item);
                setExpense(expense_item);

                const description_item = items.map(item => item.Description);
                console.log('descriptions:', description_item);
                setDescription(description_item);


            } catch (error) {
                // Handle the error
                console.error('Error fetching items:', error);
            }
            };
        DynamoDBData();

    }, []);


    useEffect(() => {
        const fetchImages = async () => {
          const urls = await usefetchImagesFromS3(imageKeys);
          setImageUrls(urls);
        };
        fetchImages();
      }, [imageKeys]);
    //React.Fragment is for component
    return (
        <div>
            <div class="container my-1 py-1 text-left"> 
                <div class="row justify-content-center" id='team'>
                        {imageUrls.map((url, index) => (
                            <React.Fragment key={index}> 
                                <Post image={url}           
                                date={date[index]}
                                expense={expense[index]}
                                description={description[index]}/>
                            </React.Fragment>
                        ))}
                </div>
            </div>
        </div>
    );
  };
  
  export default Homepage;
import React, { useState , useEffect } from 'react';
import { usehandleFileUpload }  from './utils/S3import'; 
import { usewriteToDynamoDB } from './utils/writeToDynamoDB';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import 'bootstrap/dist/css/bootstrap.min.css';


const FileUploadPage = () => {

  
  const [redirected, setRedirected] = useState(false);
  useEffect(() => {
    const poolData = {
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      ClientId: process.env.REACT_APP_CLIENT_ID,
    };

    try {
      const userPool = new CognitoUserPool(poolData);
      const cognitoUser = userPool.getCurrentUser();
      

      if (cognitoUser) {
        console.log('User is already authenticated:', cognitoUser.getUsername());
      } else if (!redirected && !window.location.href.includes('code=')) {
        // Redirect to Cognito hosted UI for sign-in only if not already redirected and 'code' is not present in the URL
        setRedirected(true);
        window.location.href = `https://mengco.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin&redirect_uri=${encodeURIComponent('https://mengcomedia.com/fileuploadpage')}`;

      } 
    } catch (error) {
      console.error('Error fetching current user:', error);
      // Handle the error as needed
    }
  }, [redirected]);


  // State variables to store file, description, and date
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [expense, setExpense] = useState('');

  // Handle file input change
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  // Handle description input change
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  // Handle date input change
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  // Handle date input change
  const handleExpenseChange = (event) => {
    setExpense(event.target.value);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Perform actions with the captured data (e.g., send to server, etc.)
    console.log('File:', file);
    console.log('file type', typeof file.name)
    console.log('Description:', description);
    console.log('Date:', date);
    console.log('Expense', expense);
    
    if (file) {
        usehandleFileUpload(file, file.name); //call the use function for s3 file upload
        usewriteToDynamoDB(file.name, date, expense, description);

        // Reset the file input
        setFile(null);
    } else {
        console.error('No file selected for upload.');
    }
      

    // Reset form fields
    setFile(null);
    setDescription('');
    setDate('');
    setExpense('');
  }

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Upload Expense</h1>
      <form onSubmit={handleSubmit}>
        {/* File Upload */}
        <div className="mb-3">
          <label htmlFor="file" className="form-label">Choose File:</label>
          <input type="file" className="form-control" id="file" onChange={handleFileChange} />
        </div>
        {/* Date */}
        <div className="mb-3">
          <label htmlFor="date" className="form-label">Date:</label>
          <input type="date" className="form-control" id="date" value={date} onChange={handleDateChange} />
        </div>
        {/* Expense */}
        <div className="mb-3">
          <label htmlFor="Expense" className="form-label">Expense:</label>
          <input type="text" className="form-control" id="Expense" value={expense} onChange={handleExpenseChange} />
        </div>
        {/* Description */}
        <div className="mb-3">
          <label htmlFor="description" className="form-label">Description:</label>
          <textarea className="form-control" id="description" value={description} onChange={handleDescriptionChange} />
        </div>
        {/* Submit Button */}
        <div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default FileUploadPage;

const usefetchImagesFromS3 = async (imageKeys) => {
  try {
    // Fetch images for each key from Cloudfront
    const urls = await Promise.all(
      imageKeys.map(async (imageKey) => {
        const cloudFrontDomain = 'https://d1lwq2rdf0wgel.cloudfront.net';
        const cloudFrontUrl = `${cloudFrontDomain}/uploads/${imageKey}`;
        console.log('CloudFront Pre-signed URL:', cloudFrontUrl);
        return cloudFrontUrl
        }) 
    );
    console.log('urls', urls)
    return urls;
    } 
    catch (error) {
    console.error('Error fetching images:', error);
    }
};

export {usefetchImagesFromS3};
